import React  from 'react'
import IntroAboutUsComponent from "../components/intro-about-us/intro-about-us.component";
// import AboutTextComponent from "../components/about-us-text/about-us-text.component";
// import PartnersListComponent from "../components/partners-list/partners-list.component";
import ABanner from "../components/features/ab.component";
import {MainLayout} from "../layouts/main.layout";
import Seo from "../components/seo"
import { Helmet } from 'react-helmet';
// import { Script } from 'gatsby'

const AboutUs = ({location}) => {

  return (
    <>
    {/* <Seo title='UploadLetters.com - About Us' description='Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience.' location={location.pathname} /> */}
    <MainLayout location={location}>
    <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
        </script>
      </Helmet>
      <div className="landingSlidesHolder">
        <IntroAboutUsComponent/>
        {/*<AboutTextComponent/>*/}
        <div className="mainContainer gutter60">
          <ABanner/>
        </div>
        <div id="dib-posts"></div>

      </div>
    </MainLayout>
      {/* <Script src="https://io.dropinblog.com/embedjs/5e03db61-a107-4fa4-9337-2f0a4760a919.js"/> */}
    </>
    )
}

AboutUs.defaultProps = {
  location: {},
};

export default AboutUs

export const Head = () => (
    <Seo title="UploadLetters.com - About Us" description="Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience." />
)
