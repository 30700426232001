import React from 'react'
import {Link} from "gatsby";
import { StaticQuery, graphql } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const IntroAboutUsComponent = (props) => {
  const data = props.data;
  const texts = data.allTexts.edges[0].node
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  return (
    <div className="introAboutUsSlide">
      <div className="slideContent" style={{paddingBottom: '0'}}>
        {/* <div className="mainContainer gutter120">
          <h1 className='textCenter mb20'>{t(data.allDataJson.edges[0].node.aboutPage.title, texts)}</h1>
          <div className="titleTextHolder">
            {
              data.allDataJson.edges[0].node.aboutPage.text.map((item)=>{
                return (
                  <p key={item}>
                    {t(item, texts)}
                  </p>
                )
              })
            }
          </div>
          <div className="btnHolder centered mb120">
            <Link to="/contact-us" className="button" style={{width: '100%', maxWidth: '302px'}}>Contact Us</Link>
          </div>
        </div> */}
        <div className="mainContainer gutter60">
          <div className="aboutUsImgHolder mb90">
            <GatsbyImage image={getImage(data.allDataJson.edges[0].node.aboutPage.img)} alt='About us'/>
            <div className="aboutUsTitleWrapper">
              <h1 className='aboutUsTitle'>{t(data.allDataJson.edges[0].node.aboutPage.title, texts)}</h1>
              <div className="btnHolder centered">
                <Link to="/contact-us/" className="button aboutUsTitleButton">Contact Us <div className="whiteDot"/></Link>
              </div>
            </div>
          </div>
          <div className="aboutStatHolder">
            <div className="aboutStat">
              <div className='textPrimary titlePrice mb40'>PARTNER WITH US</div>
              <div className="aboutStatDescription mb40">
                {
                  data.allDataJson.edges[0].node.aboutPage.text.map((item)=>{
                    return (
                      <>
                        <p key={item}>
                          {t(item, texts)}
                        </p>
                        <br />
                      </>
                    )
                  })
                }
              </div>
              <ul className="aboutStatList">
                {
                  data.allDataJson.edges[0].node.aboutPage.statList.map((item)=>{
                    return (
                      <li className="aulItem" key={item.qnt}>
                        <h3 className="qnt" style={{marginBottom: '12px'}}>{t(item.qnt, texts)}</h3>
                        <div className="aboutStatListCaption">{t(item.caption, texts)}</div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

const IntroAboutUsComponentQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allDataJson {
          edges {
            node {
              aboutPage {
                button
                statList {
                  caption
                  qnt
                }
                text
                title
                img {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        allTexts {
          edges {
            node {
              aboutPage_button
              aboutPage_statList_0_caption
              aboutPage_statList_0_qnt
              aboutPage_statList_1_caption
              aboutPage_statList_1_qnt
              aboutPage_statList_2_caption
              aboutPage_statList_2_qnt
              aboutPage_statList_3_caption
              aboutPage_statList_3_qnt
              aboutPage_text1
              aboutPage_text2
              aboutPage_title
            }
          }
        }
      }
    `}
    render={(data) => <IntroAboutUsComponent data={data} {...props}/>}
  />

)

export default IntroAboutUsComponentQuery